import { sbEditable, SbEditableBlok } from '@storyblok/storyblok-editable'
import * as React from 'react'
import { HotjarEvent, useTriggerHotjarSurvey } from '../lib/hooks/useTriggerHotjarSurvey';
import { DynamicComponent } from '../components/DynamicComponent'
import { Seo } from '../components/Seo'
import { DefaultLayout } from '../layouts/DefaultLayout'
import { ThemeName } from '../lib/constants'
import { useStoryblok } from '../lib/storyblok'
import useDLPageViews from '../lib/hooks/tracking/useDLPageView'

const PageTemplate = ({ pageContext }: { pageContext: SbEditableBlok }) => {
  let { story, content } = useStoryblok(pageContext)

  useDLPageViews(story)
  useTriggerHotjarSurvey(HotjarEvent.USER_PERSONA_SURVEY)

  const components = content.body?.map(blok => {
    return <DynamicComponent blok={blok} key={blok._uid} />
  })

  return (
    <DefaultLayout theme={content.color_theme ? (`theme-${content.color_theme}` as ThemeName) : undefined}>
      <Seo
        title={pageContext?.field_pageTitle_string || pageContext?.name}
        description={pageContext?.field_pageDescription_string}
        lang={pageContext?.lang}
        url={pageContext?.full_slug}
      />
      <div {...sbEditable(story)}>{components}</div>
    </DefaultLayout>
  )
}

export default PageTemplate
